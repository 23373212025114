<template>
  <v-row>
    <v-col v-if="hasVariants">
      <v-card outlined>
        <template v-for="(variant, index) in variants">
          <v-card-text
            :key="index"
            :class="{ 'primary lighten-4': isUpdating(variant.id) }"
          >
            <v-row class="d-flex align-center">
              <v-col cols="12" sm="3">
                <div class="caption text--primary">Variant #{{ ++index }}</div>
                <div class="subtitle-1 primary--text">
                  {{ variant.validity }}
                  {{
                    variant.validity_period_text
                      | validityPlural(variant.validity)
                  }}
                  Validity
                </div>
              </v-col>

              <v-col cols="12" sm="4" class="d-flex align-center">
                <div class="text-truncate">
                  <div class="caption text--primary">US Stock Keeping Unit</div>
                  <div class="subtitle-1 primary--text text-truncate">
                    {{ variant.sku_us }}
                  </div>
                </div>

                <div class="ml-auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-8"
                        color="primary"
                        v-clipboard="() => variant.sku_us"
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon small> {{ icons.copy }} </v-icon>
                      </v-btn>
                    </template>
                    <span> Copy to clipboard </span>
                  </v-tooltip>
                </div>
              </v-col>

              <v-col cols="12" sm="4" class="d-flex align-center">
                <div class="text-truncate">
                  <div class="caption text--primary">AU Stock Keeping Unit</div>
                  <div class="subtitle-1 primary--text text-truncate">
                    {{ variant.sku_au }}
                  </div>
                </div>

                <div class="ml-auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-8"
                        color="primary"
                        v-clipboard="() => variant.sku_au"
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon small> {{ icons.copy }} </v-icon>
                      </v-btn>
                    </template>
                    <span> Copy to clipboard </span>
                  </v-tooltip>
                </div>
              </v-col>

              <v-col cols="12" sm="1" class="d-flex flex-row-reverse">
                <v-menu
                  v-if="isEditable && !isUpdating(variant.id)"
                  class="ml-auto"
                  :position-y="100"
                  rounded="lg"
                  offset-y
                  bottom
                  left
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn class="ma-2" v-bind="attrs" v-on="on" icon>
                      <v-icon> {{ icons.options }} </v-icon>
                    </v-btn>
                  </template>

                  <v-list class="pa-0">
                    <v-list-item
                      class="px-10"
                      @click="editVariant(variant)"
                      link
                    >
                      <v-list-item-title> Edit Variant </v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      class="px-10"
                      @click="deleteVariant(variant)"
                      link
                    >
                      <v-list-item-title class="primary--text">
                        Delete Variant
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                  v-if="isUpdating(variant.id)"
                  color="primary"
                  @click="editVariant(null)"
                  text
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider
            v-if="!isUpdating(variant.id) && index < variants.length"
            :key="`divider-${index}`"
          />
        </template>
      </v-card>
    </v-col>

    <v-col v-else>
      <no-list :details="listLabel" />
    </v-col>
  </v-row>
</template>

<script>
import ProgramVariant from '@/models/ProgramVariant'
import NoList from '@/components/elements/NoList'

import { mdiDotsVertical, mdiContentCopy } from '@mdi/js'

export default {
  name: 'ProgramVariantList',

  components: {
    NoList,
  },

  props: {
    list: {
      type: Array,
      required: true,
      defaut: () => {
        return []
      },
    },

    onEdit: {
      type: ProgramVariant,
    },

    listLabel: {
      type: String,
      default: 'No Available Variants',
    },

    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      variants: this.list,
      icons: {
        copy: mdiContentCopy,
        options: mdiDotsVertical,
      },
      variant: null,
    }
  },

  computed: {
    hasVariants() {
      return !!this.variants.length
    },
  },

  methods: {
    editVariant(variant = null) {
      this.$emit('edit', variant)
    },

    deleteVariant(variant) {
      this.$emit('delete', variant)
    },

    isUpdating(id) {
      if (this.variant) {
        return this.variant.id === id
      } else {
        return false
      }
    },
  },

  watch: {
    list(values) {
      this.variants = values
    },

    onEdit(value) {
      this.variant = value
    },
  },
}
</script>
