<template>
  <div>
    <v-row v-if="isEditable" class="program-variant--form">
      <v-col cols="12" sm="2">
        <v-select
          v-model="form.validity_period"
          label="Period"
          :items="periods"
          :error-messages="form.$getError('validity_period')"
          :hide-details="!form.$getError('validity_period')"
          :disabled="this.form.$busy"
          item-value="value"
          item-text="name"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="2">
        <v-text-field
          v-model="form.validity"
          v-mask="'####'"
          label="Validity"
          :error-messages="form.$getError('validity')"
          :hide-details="!form.$getError('validity')"
          :disabled="this.form.$busy"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          v-model="form.sku_us"
          label="Variant USD SKU"
          :error-messages="form.$getError('sku_us')"
          :hide-details="!form.sku_us && !form.$getError('sku_us')"
          :disabled="this.form.$busy"
          :maxlength="100"
          outlined
          counter
          flat
        />
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          v-model="form.sku_au"
          label="Variant AU SKU"
          :error-messages="form.$getError('sku_au')"
          :hide-details="!form.sku_au && !form.$getError('sku_au')"
          :disabled="this.form.$busy"
          :maxlength="100"
          counter
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="2">
        <v-text-field
          v-model="form.price_us"
          label="Price in US"
          :error-messages="form.$getError('price_us')"
          :hide-details="!form.sku_us && !form.$getError('price_us')"
          :disabled="this.form.$busy"
          outlined
          counter
          flat
        />
      </v-col>

      <v-col cols="12" sm="2">
        <v-text-field
          v-model="form.price_au"
          label="Price in AU"
          :error-messages="form.$getError('price_au')"
          :hide-details="!form.sku_au && !form.$getError('price_au')"
          :disabled="this.form.$busy"
          counter
          outlined
          flat
        />
      </v-col>

      <v-col class="text-right" cols="12">
        <v-btn
          v-if="!onEdit"
          class="success--text"
          :loading="this.form.$busy"
          @click="addNewVariant"
          text
        >
          Add New Variant
        </v-btn>

        <v-btn
          v-else
          class="warning--text"
          :loading="this.form.$busy"
          @click="updateSelectedVariant"
          text
        >
          Update Variant
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProgramVariant from '@/models/ProgramVariant'
import Challenge from '@/models/Challenge'
import Program from '@/models/Program'
import Form from '@/utils/form'

import { mask } from 'vue-the-mask'
import { mapMutations } from 'vuex'
import { extend } from 'lodash'

export default {
  name: 'ProgramVariantForm',

  props: {
    for: {
      type: [Program, Challenge],
      required: true,
    },

    onEdit: {
      type: ProgramVariant,
    },

    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  directives: {
    mask,
  },

  data() {
    return {
      form: new Form({
        id: null,
        sku_us: null,
        sku_au: null,
        validity: null,
        validity_period: null,
      }),
      program: this.for,
      periods: [
        { name: 'Days', value: 'D' },
        { name: 'Weeks', value: 'W' },
        { name: 'Month', value: 'M' },
        { name: 'Year', value: 'Y' },
      ],
    }
  },

  computed: {
    isChallenge() {
      return !(this.program instanceof Program)
    },
  },

  methods: {
    ...mapMutations({
      addProgramVariant: 'programs/insertProgramVariant',
      updateProgramVariant: 'programs/updateProgramVariant',
      addChallengeVariant: 'challenges/insertChallengeVariant',
      updateChallengeVariant: 'challenges/updateChallengeVariant',
    }),

    async addNewVariant() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        let { data } = await this.program.variants().attach(this.form)

        this.form.$timeout(() => {
          this.form.$busy = false
          this.form.$reset()

          if (!this.isChallenge) {
            this.addProgramVariant({ ...data.data })
          } else {
            this.addChallengeVariant({ ...data.data })
          }
        })
      } catch ({ response }) {
        this.form.$timeout(() => {
          this.form.$busy = false

          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }
        })
      }
    },

    async updateSelectedVariant() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        let { data } = await new ProgramVariant(this.form)
          .for(this.program)
          .save()

        this.form.$timeout(() => {
          this.form.$busy = false
          this.form.$reset()
          this.$emit('update', null)

          if (!this.isChallenge) {
            this.updateProgramVariant(data)
          } else {
            this.updateChallengeVariant(data)
          }
        })
      } catch ({ response }) {
        this.form.$timeout(() => {
          this.form.$busy = false

          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }
        })
      }
    },
  },

  watch: {
    for(value) {
      this.program = value
    },

    onEdit(value) {
      this.form.$reset()

      if (value) {
        this.form = extend(this.form, value)
      }
    },
  },
}
</script>
