var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.hasVariants)?_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_vm._l((_vm.variants),function(variant,index){return [_c('v-card-text',{key:index,class:{ 'primary lighten-4': _vm.isUpdating(variant.id) }},[_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"caption text--primary"},[_vm._v("Variant #"+_vm._s(++index))]),_c('div',{staticClass:"subtitle-1 primary--text"},[_vm._v(" "+_vm._s(variant.validity)+" "+_vm._s(_vm._f("validityPlural")(variant.validity_period_text,variant.validity))+" Validity ")])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"text-truncate"},[_c('div',{staticClass:"caption text--primary"},[_vm._v("US Stock Keeping Unit")]),_c('div',{staticClass:"subtitle-1 primary--text text-truncate"},[_vm._v(" "+_vm._s(variant.sku_us)+" ")])]),_c('div',{staticClass:"ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard",value:(function () { return variant.sku_us; }),expression:"() => variant.sku_us"}],staticClass:"ml-8",attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.copy)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Copy to clipboard ")])])],1)]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"text-truncate"},[_c('div',{staticClass:"caption text--primary"},[_vm._v("AU Stock Keeping Unit")]),_c('div',{staticClass:"subtitle-1 primary--text text-truncate"},[_vm._v(" "+_vm._s(variant.sku_au)+" ")])]),_c('div',{staticClass:"ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard",value:(function () { return variant.sku_au; }),expression:"() => variant.sku_au"}],staticClass:"ml-8",attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.copy)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Copy to clipboard ")])])],1)]),_c('v-col',{staticClass:"d-flex flex-row-reverse",attrs:{"cols":"12","sm":"1"}},[(_vm.isEditable && !_vm.isUpdating(variant.id))?_c('v-menu',{staticClass:"ml-auto",attrs:{"position-y":100,"rounded":"lg","offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.options)+" ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"px-10",attrs:{"link":""},on:{"click":function($event){return _vm.editVariant(variant)}}},[_c('v-list-item-title',[_vm._v(" Edit Variant ")])],1),_c('v-divider'),_c('v-list-item',{staticClass:"px-10",attrs:{"link":""},on:{"click":function($event){return _vm.deleteVariant(variant)}}},[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" Delete Variant ")])],1)],1)],1):_vm._e(),(_vm.isUpdating(variant.id))?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.editVariant(null)}}},[_vm._v(" Cancel ")]):_vm._e()],1)],1)],1),(!_vm.isUpdating(variant.id) && index < _vm.variants.length)?_c('v-divider',{key:("divider-" + index)}):_vm._e()]})],2)],1):_c('v-col',[_c('no-list',{attrs:{"details":_vm.listLabel}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }