<template>
  <div class="bbr-program--variants">
    <v-row>
      <v-col cols="12" xl="9">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Challenge Variants</h2>

          <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            text
            depressed
            :loading="loading"
            @click="saveForLater"
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndNext"
            :loading="loading"
            text
            depressed
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xl="9" class="mb-12">
        <program-variant-form
          :for="program"
          :on-edit="variant"
          :is-editable="isEditable"
          @update="editVariant"
        />
        <program-variant-list
          class="mt-5"
          :list="variants"
          :on-edit="variant"
          :is-editable="isEditable"
          list-label="No Challenge Variants"
          @edit="editVariant"
          @delete="deleteVariant"
        />
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ProgramVariantList from '@/components/elements/programs/ProgramVariantList'
import ProgramVariantForm from '@/components/forms/programs/ProgramVariantForm'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import Challenge from '@/models/Challenge'

import { mdiArrowRight, mdiPlus } from '@mdi/js'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ChallengeVariants',

  components: {
    ConfirmDialog,
    ProgramVariantForm,
    ProgramVariantList,
  },

  data() {
    return {
      variant: null,
      loading: false,
      icons: {
        add: mdiPlus,
        next: mdiArrowRight,
      },
      program: new Challenge({ id: this.$attrs.id }),
    }
  },

  computed: {
    ...mapGetters({
      variants: 'challenges/getSelectedChallengeVariants',
      isCompleted: 'challenges/isSelectedChallengeComplete',
    }),

    isEditable() {
      return !this.isCompleted
    },

    hasVariants() {
      return !!this.variants.length
    },
  },

  created() {
    this.fetchChallengeVariants()
  },

  methods: {
    ...mapMutations({
      removeChallengeVariant: 'challenges/removeChallengeVariant',
      setChallengeVariants: 'challenges/setSelectedChallengeVariants',
    }),

    async fetchChallengeVariants() {
      let { data } = await this.program.variants().get()

      this.setChallengeVariants(data)
    },

    editVariant(variant = null) {
      this.variant = variant
    },

    async deleteVariant(variant) {
      const confirm = await this.$refs.confirmDelete.open(
        'Remove Variant',
        'Are you sure you want to remove this program variant?'
      )
      if (!confirm) return

      await variant.for(this.program).delete()

      this.removeChallengeVariant(variant)
    },

    saveForLater() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'current.challenges',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'draft.challenges',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },

    saveAndNext() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'challenge.workouts',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'new.challenge.workouts',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },
  },
}
</script>
